import React, { useState, useEffect } from "react";

const Graph = ({ answers, questions, first }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    let percentage =
      ((parseInt(answers.get(questions.a)) +
        parseInt(answers.get(questions.b))) /
        10) *
        100 +
      "%";
    let decimal =
      (parseInt(answers.get(questions.a)) +
        parseInt(answers.get(questions.b))) /
      10;
    setData({ percenatge: percentage, decimal: decimal });
  }, [answers, questions]);

  const ruler = (
    <div className="ruler">
      <span>
        <i>0</i>
      </span>
      <span>
        <i>0.1</i>
      </span>
      <span>
        <i>0.2</i>
      </span>
      <span>
        <i>0.3</i>
      </span>
      <span>
        <i>0.4</i>
      </span>
      <span></span>
      <span>
        <i>0.6</i>
      </span>
      <span>
        <i>0.7</i>
      </span>
      <span>
        <i>0.8</i>
      </span>
      <span>
        <i>0.9</i>
      </span>
      <span>
        <i>1</i>
      </span>
    </div>
  );

  return (
    <>
      <div className="detail-bar">
        {first ? ruler : null}

        <div className="bar" style={{ width: data.percenatge }}></div>
      </div>
      <div className="decimal">{data.decimal}</div>
    </>
  );
};

export default Graph;
