import React, { useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import TableData from "../data/TableData";
import AnswerSelect from "../components/AnswerSelect";
import CalculateBtns from "../components/CalculateBtns";

createTheme("solarized", {
  text: {
    primary: "#f2f2f2",
    secondary: "#2aa198",
  },
  background: {
    default: "#12263A",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#073642",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
  title: {
    background: "red",
  },
});
const customStyles = {
  rows: {
    style: {
      minHeight: "110px",
      fontSize: "13px",
    },
  },
  headCells: {
    style: {
      fontSize: "14px",
      fontWeight: 500,
    },
  },
  cells: {
    style: {},
  },
};

const ProblemSolving = (props) => {
  const [answers, setAnswers] = useState(new Map());

  const columns = [
    {
      name: "No",
      selector: "no",
      width: "50px",
      center: true,
      style: {
        backgroundColor: "#C5D8D1",
        color: "#12263A",
        fontWeight: "600",
      },
    },
    {
      name: "Durumlar / İfadeler",
      selector: "question",
      minWidth: "350px",
      wrap: true,
    },
    {
      name: "Asla",
      selector: "one",
      cell: (row) => <CustomTitle row={row} value={1} reverseValue={5} />,
      center: true,
    },
    {
      name: "Nadiren",
      selector: "two",
      cell: (row) => <CustomTitle row={row} value="2" reverseValue="4" />,
      center: true,
    },
    {
      name: "Ara Sıra",
      selector: "three",
      cell: (row) => <CustomTitle row={row} value="3" reverseValue="3" />,
      center: true,
    },
    {
      name: "Sıkça",
      selector: "four",
      cell: (row) => <CustomTitle row={row} value="4" reverseValue="2" />,
      center: true,
    },
    {
      name: "Oldukça Sık",
      selector: "five",
      cell: (row) => <CustomTitle row={row} value="5" reverseValue="1" />,
      center: true,
    },
  ];

  const CustomTitle = ({ row, value, reverseValue }) => (
    <AnswerSelect
      name={row.no}
      value={row.reverse ? reverseValue : value}
      onChange={handleChange}
    />
  );

  const handleChange = (e) => {
    let no = e.target.name;
    let value = e.target.value;

    setAnswers(answers.set(no, value));
  };

  const resetResults = () => {
    setAnswers(new Map());
  };

  return (
    <div className="container my-5">
      <DataTable
        title=""
        columns={columns}
        data={TableData}
        theme="solarized"
        customStyles={customStyles}
        fixedHeader={true}
        fixedHeaderScrollHeight="70vh"
      />
      <CalculateBtns answers={answers} resetResults={resetResults} />
    </div>
  );
};

export default ProblemSolving;
