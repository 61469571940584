import React from "react";
import PropTypes from "prop-types";

const AnswerSelect = ({ type = "radio", name, onChange, value }) => (
  <div>
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      className="form-radio"
    />
  </div>
);

AnswerSelect.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AnswerSelect;
