import React, { useState, useEffect } from "react";
import Results from "./Results";
import { Link, Element } from "react-scroll";

const CalculateBtns = ({ answers, resetResults }) => {
  const [totalPoints, setTotalPoints] = useState("-/-");
  const [completed, setCompleted] = useState(false);
  const requiredAnswerCount = 16;

  useEffect(() => {
    if (answers.size === 0) {
      setCompleted(false);
    }
  }, [answers]);

  const calculateResults = () => {
    let answerCount = answers.size;

    if (answerCount === requiredAnswerCount) {
      let answerArray = Array.from(answers.values());
      let total = answerArray.reduce((a, b) => parseInt(a) + parseInt(b), 0);

      setTotalPoints(total);
      setCompleted(true);
    } else {
      alert("Tüm soruları cevaplamalısın!!!");
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center my-4">
        <Link
          activeClass="active"
          to="results"
          spy={true}
          smooth={true}
          offset={50}
          duration={1000}
        >
          <button
            onClick={calculateResults}
            className="btn btn-primary mx-2 px-5"
          >
            HESAPLA
          </button>
        </Link>

        <button onClick={resetResults} className="btn btn-warning mx-2 px-5">
          SIFIRLA
        </button>
      </div>
      <Element name="results">
        {completed ? <Results total={totalPoints} answers={answers} /> : null}
      </Element>
    </>
  );
};

export default CalculateBtns;
