import React from "react";
import html2canvas from "html2canvas";
import Graph from "./Graph";

const Results = ({ total, answers }) => {
  function download(dataurl, filename) {
    var a = document.createElement("a");
    a.href = dataurl;
    a.setAttribute("download", filename);
    a.click();
  }

  const takeScreenShot = () => {
    html2canvas(document.body).then(function (canvas) {
      var dataURL = canvas.toDataURL("image/jpeg");
      download(dataURL, "sonuclar.jpeg");
    });
  };

  return (
    <div className="problem-results px-0 px-md-5" id="results">
      <div className="total-points">
        TOPLAM PUAN <b>{total}</b>
      </div>

      <div className={total <= 36 ? "user-block" : "result-block"}>
        <p>
          <b>16 - 36 Puan arası:</b> Muhtemelen problemleri heyecan verici ve
          gerekli değişikliği yapma fırsatı olarak görmek yerine olumsuz birer
          unsur olarak görme eğilimindesiniz. Problem çözme yaklaşımınız
          sistematik olmaktan ziyade sezgiseldir ve bu geçmişte bazı kötü
          deneyimlere yol açmış olabilir. Daha fazla pratik yaparak ve daha
          yapılandırılmış bir yaklaşım izleyerek, bu önemli beceriyi
          geliştirebilir ve sorunları daha etkili bir şekilde çözmeye
          başlayabilirsiniz.
        </p>
      </div>
      <div
        className={total <= 58 && total >= 37 ? "user-block" : "result-block"}
      >
        <p>
          <b>37 - 58 Puan arası:</b> Problem çözme yaklaşımınız biraz
          "gelişigüzel" görünüyor. Bazen çözümleriniz gerçekten iyi çalışırken,
          bazen de işe yaramamaktadır. Genellikle ne yapmanız gerektiğini anlar
          ve yapılandırılmış bir problem çözme sürecine sahip olmanın önemli
          olduğunu bilirsiniz. Ancak, her zaman bu süreci izlemezsiniz.
          Tutarlılığınız üzerinde çalışarak ve sürece bağlı olarak, önemli
          gelişmeler gösterebilirsiniz.
        </p>
      </div>
      <div className={total >= 59 ? "user-block" : "result-block"}>
        <p>
          <b>59 - 80 Puan arası:</b> Kendine güvenen bir problem çözücüsünüzdür.
          Sorunu anlamak, doğru bir karar için kriterleri anlamak ve bazı iyi
          seçenekler oluşturmak için zaman ayırırsınız. Sorunlara sistematik bir
          şekilde yaklaştığınız için her seferinde temel konuları kapsama
          alırsınız. Bu doğrultuda kararlarınız her zaman iyi düşünülmüş, iyi
          planlanmış ve iyi bir şekilde icra edilmiş durumdadır. Sorun çözme
          becerilerinizi mükemmelleştirmeye ve bunları kuruluşunuzdaki sürekli
          iyileştirme girişimleri için kullanmaya devam edebilirsiniz.
          Puanlarınızın düşük çıktığı bölümleri gözden geçirmenizi ve
          becerilerinizi daha da keskinleştirmenizi öneririz.
        </p>
      </div>

      <div className="details px-3">
        <div className="px-3 text-center my-5 h5 graph-title">
          BİREYSEL GÜÇLÜ & GELİŞİM NOKTALARI
        </div>

        <div className="detail-block">
          <div className="detail-name">Problemi Belirlemek</div>
          <Graph
            questions={{ a: "7", b: "12" }}
            answers={answers}
            first={true}
          />
        </div>
        <div className="detail-block">
          <div className="detail-name">Unsurları Belirlemek</div>
          <Graph questions={{ a: "10", b: "14" }} answers={answers} />
        </div>
        <div className="detail-block d-flex justify-content-between">
          <div className="detail-name">Problemi Tanımlamak</div>
          <Graph questions={{ a: "3", b: "9" }} answers={answers} />
        </div>
        <div className="detail-block d-flex justify-content-between">
          <div className="detail-name">Fikir Bulmak</div>
          <Graph questions={{ a: "4", b: "13" }} answers={answers} />
        </div>
        <div className="detail-block d-flex justify-content-between">
          <div className="detail-name">Seçmek ve Değerlendirmek</div>
          <Graph questions={{ a: "6", b: "15" }} answers={answers} />
        </div>
        <div className="detail-block d-flex justify-content-between">
          <div className="detail-name">Planlamak</div>
          <Graph questions={{ a: "1", b: "16" }} answers={answers} />
        </div>
        <div className="detail-block d-flex justify-content-between">
          <div className="detail-name">Fikri Satmak</div>
          <Graph questions={{ a: "5", b: "8" }} answers={answers} />
        </div>
        <div className="detail-block d-flex justify-content-between">
          <div className="detail-name">Eyleme Geçmek</div>
          <Graph questions={{ a: "2", b: "11" }} answers={answers} />
        </div>
      </div>

      <div className="text-center my-5">
        <button
          onClick={takeScreenShot}
          className="btn btn-warning btn-sm mx-2 px-2 my-5"
        >
          EKRAN GÖRÜNTÜSÜ AL
        </button>
      </div>
    </div>
  );
};

export default Results;
