const TableData = [
  {
    id: 1,
    no: "1",
    question:
      "Bir çözüm belirledikten sonra, tamamlanması için gerekli durumların sırasını içeren bir uygulama planı geliştiririm.",
    reverse: false,
  },
  {
    id: 2,
    no: "2",
    question:
      "Bir çözümü uyguladıktan sonra, hemen fikri iyileştirmenin ve gelecekteki sorunlardan kaçınmanın yollarını ararım.",
    reverse: false,
  },
  {
    id: 3,
    no: "3",
    question:
      "Yanlış soru sormaktan kaçınmak için, sorunu çözmeye çalışmadan önce her sorunu dikkatlice tanımlamaya özen gösteririm.",
    reverse: false,
  },
  {
    id: 4,
    no: "4",
    question:
      "Sorunlara farklı açılardan bakmaya ve birden çok çözüm üretmeye çalışırım.",
    reverse: false,
  },
  {
    id: 5,
    no: "5",
    question:
      "Başkalarının benim çözümümü anlaması ve desteklemesi için, önerdiğim değişikliğin varsa politik neticelerini ve diğer sonuçlarını ele almaya çalışırım.",
    reverse: false,
  },
  {
    id: 6,
    no: "6",
    question:
      "Potansiyel çözümleri, önceden belirlenmiş bir standarda göre dikkatle ve kapsamlı bir şekilde değerlendiririm.",
    reverse: false,
  },
  {
    id: 7,
    no: "7",
    question:
      "Gelecekte sorun teşkil edebilecek problemleri sistematik olarak araştırırım.",
    reverse: false,
  },
  {
    id: 8,
    no: "8",
    question:
      "Bir çözüme karar verdiğimde, nasıl bir karşıtlıkla karşılaşırsam karşılaşayım o çözümü gerçekleştiririm.",
    reverse: true,
  },
  {
    id: 9,
    no: "9",
    question:
      "Küçük sorunların genellikle kapsam olarak çok daha büyük hale gelebildiğini ve böylece çözülmesinin çok zor olduğunu düşünürüm.",
    reverse: true,
  },
  {
    id: 10,
    no: "10",
    question: "Kendime problemin doğası hakkında birçok farklı soru sorarım.",
    reverse: false,
  },
  {
    id: 11,
    no: "11",
    question:
      "Çözümümü uyguladıktan sonra rahatlayıp tekrar düzenli görevlerime odaklanırım.",
    reverse: true,
  },
  {
    id: 12,
    no: "12",
    question:
      "Mevcut operasyonların sorunsuz bir şekilde çalışmasına odaklanır ve sorunların ortaya çıkmamasını umut ederim.",
    reverse: true,
  },
  {
    id: 13,
    no: "13",
    question:
      "Potansiyel çözümleri, onları düşündüğüm şekliyle değerlendiririm.",
    reverse: true,
  },
  {
    id: 14,
    no: "14",
    question:
      "Bir probleme çözüm bulmam gerektiğinde, genellikle çözüm için gereken tüm bilgilere sahibimdir.",
    reverse: true,
  },
  {
    id: 15,
    no: "15",
    question:
      "Çözümleri değerlendirirken, seçenekler arasında nasıl karar vermem gerektiğini düşünmeye zaman ayırırım.",
    reverse: false,
  },
  {
    id: 16,
    no: "16",
    question:
      "Karar vermek, benim için problem çözme sürecimin son noktasıdır.",
    reverse: true,
  },
];

export default TableData;
