import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const images = require.context("../img", true);
const puzzles = [
  { num: "1", img: "./1.jpg" },
  { num: "2", img: "./2.jpg" },
  { num: "3", img: "./3.jpg" },
  { num: "4", img: "./4.jpg" },
];

function Home() {
  return (
    <div className="container">
      <div className="flex-container">
        <div className="row px-0 px-lg-2 flex-item-1">
          {puzzles.map((puzzle) => {
            return (
              <div
                className="col-12 col-md-6 col-lg-4 puzzle-block mx-auto"
                style={{ maxWidth: "400px" }}
                key={puzzle.num}
              >
                <span>
                  PUZZLE <br />
                  <b>{puzzle.num}</b>
                </span>
                <div className="img-holder">
                  <a href={`/puzzle/${puzzle.num}/`}>
                    <LazyLoadImage
                      height="220"
                      width="400"
                      src={images(puzzle.img)}
                      alt="puzzle"
                      className="img-fluid"
                    />
                  </a>
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="text-center my-3 flex-item-2"
          style={{ float: "left", width: "100%" }}
        >
          <img src={images(`./logo.png`)} alt="logo" />
        </div>
      </div>
    </div>
  );
}

export default Home;
