import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import ProblemSolving from "./pages/ProblemSolving";
import "./App.css";

export default function App() {
  return (
    <div>
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/karmasik-problem-cozme-testi">
          <ProblemSolving />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </div>
  );
}
